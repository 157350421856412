<template>
  <div>
    <form
      @submit.prevent="applyVoucher"
      v-if="!voucherItems.length"
      class="flex flex-col mt-2"
    >
      <div class="w-100">
        <p>
          Heb je een voucher code van je docent? Vul deze hier in om je
          artikelen te reserveren.
        </p>
      </div>

      <div class="flex w-100">
        <input
          v-model="voucher"
          type="text"
          placeholder="Voucher code"
          class="form-control w-75"
        />
        <button type="submit" class="btn btn-primary w-25">
          Voucher toepassen
        </button>
      </div>
    </form>

    <div v-if="noVoucherItems" class="flex mt-3 flex-col">
      Er zijn geen artikelen voor de opgegeven voucher gevonden.

      <router-link to="name"></router-link>
    </div>

    <div v-if="voucherItems.length" class="mt-4 test-ver">
      <p>
        Geef in de onderstaande tabel de aantallen van de artikelen die je wilt
        reserveren.
      </p>
      <b-table :items="voucherItems" :fields="fields">
        <template v-slot:cell(AmountSelected)="data">
          <input
            v-model="items[data.item.ItemID]"
            :maxlength="data.item.AmountLeft"
            @change="
              items[data.item.ItemID] =
                items[data.item.ItemID] > data.item.AmountLeft
                  ? data.item.AmountLeft
                  : items[data.item.ItemID]
            "
            class="form-control"
            min="1"
            type="number"
          />
        </template>
      </b-table>

      <button @click="makeReservation" class="btn btn-primary">
        Artikelen reserveren
      </button>
    </div>
  </div>
</template>

<script>
import GetVoucherItems from '@/services/voucher/GetVoucherItems'
import MakeVoucherReservation from '@/services/voucher/MakeVoucherReservation'
import { getCart } from '@/services/CartService'
export default {
  name: 'Voucher',
  data() {
    return {
      voucher: '',
      voucherItemCollection: {},
      items: {},
      fields: [
        {
          key: 'ItemDescription',
          label: 'Artikel'
        },
        {
          key: 'Amount',
          label: 'Aantal gereserveerd'
        },
        {
          key: 'AmountLeft',
          label: 'Aantal beschikbaar'
        },
        {
          key: 'AmountSelected',
          label: 'Aantal'
        }
      ]
    }
  },
  computed: {
    noVoucherItems() {
      return this.voucherItemCollection?.Total === 0
    },
    voucherItems() {
      return this.voucherItemCollection?.Collection ?? []
    },
    formattedItems() {
      const items = []
      // loop through all object properties in this.items and push them to items as an object
      for (const key in this.items) {
        if (this.items.hasOwnProperty(key)) {
          const item = {
            Amount: this.items[key],
            DateTimeBusinessStart: this.$store.getters.startDate,
            DateTimeBusinessEnd: this.$store.getters.endDate,
            WarehouseID: 'BPH'
          }
          const compositionID = this.voucherItems.find(
            item => item.ItemID === key
          ).CompositionID

          // Server does not want the ItemID so we send the CompositionID instead if found and not both
          if (compositionID) {
            item.CompositionID = compositionID
          } else {
            item.ItemID = key
          }

          items.push(item)
        }
      }
      return items
    }
  },
  methods: {
    async applyVoucher() {
      this.voucherItemCollection = await GetVoucherItems({
        voucher: this.voucher
      })
    },
    async makeReservation() {
      this.loading = true
      if (this.formattedItems.length < 1) {
        this.$toasted.show('Je moet minstens 1 artikel selecteren om te reserveren')
        return
      }
      const reservation = await MakeVoucherReservation({
        voucher: this.voucher,
        items: this.formattedItems,
        contactId: this.$store.getters.account.ContactID
      })

      this.$store.commit('setLatestReservation', {
        reservation
      })
      this.items = await getCart({ notes: this.notes })
      this.$router.push({
        name: 'confirmation',
        params: { lang: this.$i18n.locale }
      })
    }
  }
}
</script>

<style scoped></style>
