import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=b80fce98&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80fce98",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.shop.hva/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTable} from 'bootstrap-vue'
    installComponents(component, {BTable})
    

export default component.exports